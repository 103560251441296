.footerSection {
    background-color: midnightblue;
    height: 6rem;
    color: white;
    font-size: 1.2rem;
}

.footerSectionContent {
    max-width: 1400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.scrollToTop {
    cursor: pointer;
}