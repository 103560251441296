.btn {
  /* background-color: ; */
  box-shadow: 1px 2px 5px grey;
  border: 0rem;
  border-radius: 0.25rem;
  border-color: black;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: grey;
  font-family: Arial, Helvetica, sans-serif;
}

.btn:hover {
  cursor: default;
  box-shadow: 2px 3px 6px grey;
}

.btn img {
  border-radius: 50%;
}

.google-logo {
  height: 1.2rem;
  width: 1.2rem;
  margin: 0rem 0.75rem 0rem 0rem;
}

.btn p {
  margin: 0rem 0rem 0rem 0rem;
}

/* .loggedIn {
  color: #ff4949;
} */

.isMyVideos {
  color: grey;
}

@media screen and (min-width: 768px) {
  .btn {
    padding: 0.5rem 1rem;
  }

  .btn img {
    height: 2rem;
    width: 2rem;
    margin: 0rem 0.75rem 0rem 0rem;
  }

  .btn p {
    font-size: 1rem;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .btn {
    padding: 0.4rem 0.8rem;
  }

  .btn img {
    height: 1.6rem;
    width: 1.6rem;
    margin: 0rem 0.6rem 0rem 0rem;
  }

  .btn p {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 575px) {
  .btn {
    padding: 0.3rem 0.6rem;
  }

  .btn img {
    height: 1.2rem;
    width: 1.2rem;
    margin: 0rem 0.5rem 0rem 0rem;
  }

  .btn p {
    font-size: 0.75rem;
  }
}
