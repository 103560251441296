.textButton {
    width: auto;
    height: 40px;
    padding: 0px;
    background-color: #FF4949;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: white;
    font-weight: 500;
    padding: 0px 10px;
    cursor: pointer;
}

.textButton:hover,
.textButton:active {
    background-color: #c93838;
}