.caption {
    line-height: 1.5rem;
    font-size: 1rem;
}

.caption:hover {
    text-decoration: underline;
    cursor: pointer;
}

.activeCaption {
    background-color: darkgrey;
}