.noteControl {
  display: flex;
  margin: 20px 0px 15px 0px;
}

.timeStamp {
  border-radius: 5px;
  font-size: 1.2rem;
  text-align: center;
  max-width: fit-content;
  width: 10ch;
  margin: 0px 10px 0px 0px;
  border-width: 2px;
  border-style: inset;
  border-color: rgb(118, 118, 118);
}

.quill {
  height: 60vmin;
}
