.details-card {
  border-radius: 10px;
  height: 100%;
  background-color: #f0f0f0;
  width: 100%;
  max-width: 450px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  color: white;
  border-radius: 10px 10px 0px 0px;
}

.price {
  text-align: center;
  margin: 2rem 0rem 2rem 0rem;
}

.price h1 {
  font-size: 4rem;
  display: inline;
}

.price p {
  font-size: 1.2rem;
  display: inline;
}

.divider {
  width: 80%;
  height: 2px;
  background-color: lightgray;
  margin: auto;
}

.features {
  list-style-type: none;
  margin: 3rem 3rem;
  flex-grow: 1;
}

.features li {
  margin-bottom: 0.5rem;
}

.features img {
  width: 20px;
  height: 20px;
}

.features span {
  margin-left: 0.5rem;
}

.btn {
  width: 70%;
  /* background-color: red; */
  margin-bottom: 30px;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  border-radius: 5px;
  height: 2.5rem;
  border: none;
  font-size: 1rem;
  font-weight: 500;
}
