.pricingPageContent {
  max-width: 1000px;
  width: 100%;
}

.tabs {
  display: inline-flex;
  border-radius: 10px;
  background-color: lightgrey;
  min-height: 0px;
}

.tab {
  padding: 9px 13px;
  margin: 3px;
  min-width: 0px;
  min-height: 0px;
  border-radius: 8px;
  text-transform: none;
  font-weight: 600;
}

.stripeButton {
  width: 100%;
  height: 50px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #0074d4;
  font-weight: 500;
  font-size: 100%;
  color: white;
}

:global .Mui-selected {
  background-color: white;
  color: black !important;
  opacity: 0.6 !important;
}
