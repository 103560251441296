.main-section {
  text-align: center;
}

.mainSectionContent {
  max-width: 1400px;
}

@media screen and (min-width: 768px) {
  .main-section h1 {
    font-size: 6rem;
  }

  .main-section h5 {
    margin-top: 60px;
    font-size: 2rem;
  }

  .main-section {
    margin-top: 8rem;
    margin-bottom: 12rem;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .main-section h1 {
    font-size: 4rem;
  }

  .main-section h5 {
    margin-top: 60px;
    font-size: 1.5rem;
  }

  .main-section {
    margin-top: 6rem;
    margin-bottom: 9rem;
  }
}

@media screen and (max-width: 575px) {
  .main-section h1 {
    font-size: 3rem;
  }

  .main-section h5 {
    margin-top: 60px;
    font-size: 1.2rem;
  }

  .main-section {
    margin-top: 4rem;
    margin-bottom: 6rem;
  }
}

.highlighted {
  color: #6b5596;
}

.subtitle {
  color: grey;
}

.form {
  margin-top: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkInput {
  height: 36px;
  width: 20rem;
  max-width: 70%;
  font-size: 1.2rem;
  background-color: #f2f2f2;
  border-width: 1px 0px 1px 1px;
  /* border: solid; */
  padding: 0px 10px;
  border-radius: 5px 0px 0px 5px;
}

.goButton {
  height: 38px;
  width: 4rem;
  max-width: 10%;
  font-weight: 500;
  font-size: 1.2rem;
  background-color: #ff4949;
  color: white;
  cursor: pointer;
  border-width: 1px 1px 1px 0px;
  border-radius: 0px 5px 5px 0px;
}
