.callToActionSection {
  /* display: flex;
    align-items: center;
    justify-content: center; */
    background-color: #f5f5f5;
}

.header-text {
  text-align: center;
  /* color: #FF4949; */
}

.form {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkInput {
  height: 36px;
  width: 20rem;
  max-width: 70%;
  font-size: 1.2rem;
  background-color: #f2f2f2;
  border-width: 1px 0px 1px 1px;
  /* border: solid; */
  padding: 0px 10px;
  border-radius: 5px 0px 0px 5px;
}

.goButton {
  height: 38px;
  width: 4rem;
  max-width: 10%;
  font-weight: 500;
  font-size: 1.2rem;
  background-color: #ff4949;
  color: white;
  cursor: pointer;
  border-width: 1px 1px 1px 0px;
  border-radius: 0px 5px 5px 0px;
}
