.contactUsPageContent {
  max-width: 400px;
  width: 100%;
}

.contactUsPageContent input{
  width: 100%;
}

.contactUsPageContent textarea {
    width: 100%;
}

.card {
  border-radius: 10px;
  height: 100%;
  background-color: #f0f0f0;
  width: 100%;
  max-width: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.submitButton {
  font-weight: 500;
  font-size: 1.2rem;
  background-color: #ff4949;
  color: white;
  cursor: pointer;
  border-width: 1px 1px 1px 1px;
  border-radius: 5px 5px 5px 5px;
  width: 100%;
}
