.videoCard {
  width: 320px;
  padding: 0px 0px 20px 0px;
  border-radius: 8px;
  box-shadow: 1px 2px 5px lightgray;
  cursor: pointer;
  background-color: white;
}

.videoCard:hover {
  box-shadow: 0 0 0 3px #ff4949;
}

.thumbnail {
  height: 180px;
  width: 320px;
  object-fit: cover;
  border-radius: 8px 8px 0px 0px;
}

.videoDescription {
  margin-top: 20px;
  padding: 0px 10px 0px 10px;
  width: 320px;
  background-color: white;
}

.videoDescription h3 {
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.additional {
  font-size: 0.9rem;
  margin-top: 20px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionIcon {
  padding: 0px 0px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.actionIcon img {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.actions {
  display: flex;
  align-items: center;
}

.clockIcon {
  height: 13px;
  width: 13px;
  margin-right: 4px;
}

.duration {
  display: flex;
  align-items: center;
  justify-content: center;
}

[hidden] {
  width: 0px;
  display: none;
}
