.split {
  /* display: flex;
    flex-direction: column; */
}

.quill {
  margin: 15px;
}

.actions {
}

.scrollable-div {
  height: 70vh; /* Set the desired height for the scrollable div */
  overflow-y: scroll; /* Enable vertical scrolling */
  scroll-behavior: smooth;
}
