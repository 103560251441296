.header {
  background-color: #f5f5f5;
  box-shadow: 0px 1px 10px grey;
}

.headerContent {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  max-width: 1400px;
  width: 100%;
}

.headerContentLeft {
  height: 100%;
}

.headerContentMobile {
  height: 100%;
  width: 100%;
}

.logo {
  cursor: pointer;
}

.actions {
  font-weight: 600;
  color: #6b5596;
  font-family: Arial, Helvetica, sans-serif;
  cursor: default;
}

.actions:hover {
  color: blue;
}

@media screen and (min-width: 768px) {
  .logo {
    height: 4rem;
  }

  .headerContent {
    height: 6rem;
  }

  .actions {
    font-size: 1.2rem;
    margin-left: 50px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .logo {
    height: 3rem;
  }

  .headerContent {
    height: 5rem;
  }

  .actions {
    font-size: 1rem;
    margin-left: 30px;
  }
}

@media screen and (max-width: 575px) {
  .logo {
    height: 2rem;
  }

  .headerContent {
    height: 4rem;
  }

  .actions {
    font-size: 0.85rem;
    margin-left: 15px;
  }
}

.hamburger {
  height: 45px;
}

.emptyDiv {
  width: 20px;;
}

.sidebarActions {
  font-weight: 600;
  color: #6b5596;
  font-family: Arial, Helvetica, sans-serif;
  cursor: default;
}

.sidebarActions:hover {
  color: blue;
}

/* .divider {
    width: 80%;
} */
