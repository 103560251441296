.useCaseSection {
  /* background-color: #f5f5f5; */
  background-color: white;
  padding-top: 50px;
  padding-bottom: 50px;
}

.useCaseSectionContent {
  max-width: 1400px;
}

/* .useCaseImage {
    width: 75%;
} */

@media only screen and (min-width: 768px) {
  .order1 {
    order: 1;
  }
}

@media only screen and (min-width: 768px) {
  .order2 {
    order: 2;
  }
}

.useCaseSection h3 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.useCaseSection p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

/* .useCaseSection img {
    width: 32rem;
} */
