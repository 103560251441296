.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 30vh;
  left: 35%;
  width: 30%;
  z-index: 100;
  overflow: hidden;
  background-color: lightblue;
  padding: 3rem;
  border-radius: 1rem;
}

.cancel {
    text-align: right;
    cursor: pointer;
}

.stripeButton {
  width: 100%;
  height: 50px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: #0074d4;
  font-weight: 500;
  font-size: 100%;
  color: white;
}
