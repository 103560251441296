.featuresSection {
  background-color: #f5f5f5;
  padding-top: 50px;
  padding-bottom: 50px;
}

.featuresSection p {
    font-size: 1.2rem;
}

.featuresSectionContent {
  max-width: 1400px;
}

.highlighted {
  color: #6b5596;
}

.image {
    width: 90%;
    max-width: 360px;
}

.link {
    color: blue;
    cursor: pointer;
    text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .order1 {
    order: 1;
  }
}

@media only screen and (min-width: 768px) {
  .order2 {
    order: 2;
  }

  .emptyDiv {
    height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .emptyDiv {
    height: 20px;
  }
}