.note {
  margin: 30px 20px;
  cursor: pointer;
}

.note:hover > .noteHeader {
  background-color: #ff4949;
}

.note:hover > .noteBody {
  background-color: lightgray;
}

.noteHeader {
  display: flex;
  align-items: center;
  color: white;
  /* background-color: aliceblue; */
  background-color: #f7a6a6;
  padding: 10px;
  border-radius: 5px 5px 0px 0px;
  margin: 0px;
}

.separator {
  width: 100%;
}

.timeStamp {
  /* background-color: darkblue; */
  background-color: black;
  border-radius: 8px;
  font-size: 0.8rem;
  padding: 5px;
}

.ai {
  background-color: black;
  border-radius: 8px;
  font-size: 0.8rem;
  padding: 5px 10px 5px 10px;
  margin: 0px 8px;
}

.actions {
  padding: 0px;
  display: flex;
  align-items: center;
}

.noteHeaderIcon {
  /* height: 16px;
    width: 16px; */
  padding: 0px 0px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: right;
}

[hidden] {
  width: 0px;
  display: none;
}

.noteHeaderIcon img {
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  cursor: pointer;
}

.noteBody {
  background-color: #e8e8e8;
  margin: 0px;
  padding: 10px;
  border-radius: 0px 0px 5px 5px;
  word-wrap: break-word;
}
