.iconButton {
    width: 40px;
    height: 40px;
    padding: 0px;
    background-color: #FF4949;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.iconButton.waiting,
.iconButton.waiting:hover,
.iconButton.waiting:active {
    background-color: lightcoral;
}

.iconButton:hover,
.iconButton:active {
    background-color: #c93838;
}

.waiting {
    background-color: lightcoral;
}

div[aria-disabled="true"] {
    background-color: grey;
}

div[aria-disabled="true"]:hover {
    background-color: grey;
    cursor: not-allowed;
}

.img {
    width: 24px;
    height: 24px;
}