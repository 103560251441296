.videosPage {
    
}

.videosPageContent {
    max-width: 1400px;
    width: 100%;
}

.videoCards {
    margin: 30px 0px;
}

.noVideos {
    font-size: 1.3rem;
}