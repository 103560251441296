.notesSection {
  width: auto;
  background-color: white;
}

.notesSection hr {
  margin: 0px -10px;
  color: blue;
  opacity: 0.3;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 15px 15px 15px 15px;
}

.navigation div {
  font-weight: 700;
  font-size: 1.2rem;
  color: #6b5596;
  /* color: grey; */
  cursor: pointer;
}

.navigation div:hover {
  color: blue;
}

.tabs {
  font-size: 1 rem;
}

.tab {
  position: relative;
  font-weight: 500;
  color: #696969;
}

.tab:hover {
  color: black;
  cursor: pointer;
}

/* .border-tab {

} */

.activeTab {
  color: black;
}

.activeTab::before {
  content: "";
  width: 50%;
  display: inline-block;
  border-bottom: 1.5px solid red;
  position: absolute;
  left: 25%;
  bottom: 0%;
}

/* .border-tab::after {
  content: "";
  height: 50%;
  display: inline-block;
  border-left: 1.5px solid lightgray;
  position: absolute;
  right: 0;
  top: 25%;
} */

.selectView {
  padding: 15px;
  margin: 0px 0px;
  display: flex;
  justify-content: space-between;
}

.verticalGap {
  width: 8px;
}

.selectViewLeft {
  display: flex;
}

.align-right {
  justify-content: right;
}

.align-left {
  justify-content: left;
}

.children {
  overflow: scroll;
  height: 75vh;
  overflow-y: scroll;
}

pre {
  white-space: pre-wrap;
}

.scrollable-div {
  height: 70vh; /* Set the desired height for the scrollable div */
  overflow-y: scroll; /* Enable vertical scrolling */
}
